import { render, staticRenderFns } from "./PageTitle.vue?vue&type=template&id=757c9f2c"
import script from "./PageTitle.vue?vue&type=script&lang=js"
export * from "./PageTitle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\DAEDONG-ESF\\FrontEnd\\DAEDONGESFFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('757c9f2c')) {
      api.createRecord('757c9f2c', component.options)
    } else {
      api.reload('757c9f2c', component.options)
    }
    module.hot.accept("./PageTitle.vue?vue&type=template&id=757c9f2c", function () {
      api.rerender('757c9f2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Layout/Components/PageTitle.vue"
export default component.exports